$icons: (
  bandage: bandage,
  chemo: chemo,
  children: children,
  doctor: doctor,
  family: family,
  food: food,
  heart: heart,
  handheart: handheart,
  hospital: hospital,
  hostevent: hostevent,
  kid: kid,
  nobill: nobill,
  nurse: nurse,
  paintbrush: paintbrush,
  wagon: wagon,
  researcher: researcher,
  team: team,
  travel: travel,
  treatment: treatment,
);

.commitment {
  &-wrapper {
    height: 100vh;
    background: url("../../../img/campus_comm.jpg") no-repeat;
    background-size: 280%;
    background-position: 70% 100%;
  }
  &-list {
    &-wrapper {
      &:before {
        content: "";
        display: block;
        opacity: 0.8;
        width: 100%;
        height: 80vh;
        background-image: linear-gradient(135deg, #c10f3a 45%, #111);
        // background: $black;
        background-size: 100%;
        transform: translateY(15%);
        clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
      }

      .search {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 90%;
        height: 40vh;
        // background-color: $white;

        .sj-logo {
          //   @include _(mb-m);
          img {
            width: 35%;
          }
        }

        &-box {
          @include _(p-s);
          border-radius: $radius;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          position: relative;
          //   border: 1px solid $white;

          .dollar {
            @include _(fs-l);
            @include _(fw-bold);
            position: absolute;
            bottom: 69px;
            // left: 226px;
            left: 200px; // changed for TVs
            color: $black;
          }

          h1 {
            @include _(fw-bold);
            @include _(fs-xl);
            color: $white;
            text-shadow: 0px 1px 5px $grey-90;
          }

          p {
            @include _(fs-ml);
            @include _(mv-s);
            text-align: center;
            // @include _(fw-bold);
            color: $white;
            text-shadow: 0px 1px 5px $grey-90;
          }

          button {
            display: none;
          }
        }

        form {
          display: flex;
          justify-content: center;
        }

        input {
          &.goal {
            @include _(fs-l);
            @include _(fw-bold);
            @include _(mt-ml);
            border-radius: $radius;
            border: 0px;
            padding: 2rem 5rem 2rem 4.5rem;
            width: 80%;
            line-height: 2.25rem;

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: $grey-10;
            }
          }
        }
      }
      .gtg-list {
        &-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 90%;
          height: 50vh;
        }

        margin: 0 auto;
        list-style-type: none;
        width: 90%;
        height: 415px;
        // border-radius: 10px;
        // border: 3px solid $white;
        // box-shadow: 0px -1px 7px 2px #000;
        position: relative;
        // background: $swatch-1;
        position: relative;

        ul {
          @include _(mv-m);
          @include _(ph-xs);

          z-index: 2;
          position: relative;
          width: 830px;

          li {
            @include _(pv-xs);
            @include _(ph-xs);
            // @include _(mv-xxs);
            // @include _(fs-l);
            font-size: 2.5rem;
            list-style-type: none;
            display: flex;
            justify-content: end;
            align-items: center;
            // background: rgba($white, 0.8);
            // border-radius: $radius;
            color: $white;

            .icon {
              height: 90px;
              width: 90px;
              background-position: center bottom;
              background-size: 75%;
              filter: drop-shadow(0px 1px 5px $grey-90);

              @each $icons, $value in $icons {
                &.#{$icons} {
                  background: url("../../../img/icons/" + $value + ".png")
                    no-repeat;
                  background-size: contain;
                  &.grey {
                    background: url("../../../img/icons/" + $value + "_grey.png")
                      no-repeat;
                    background-size: contain;
                  }
                }
              }
            }

            .text {
              @include _(ph-ms);
              @include _(fw-light);
              width: 75%;
              line-height: 1.2em;
              text-shadow: 0px 1px 5px $grey-90;

              &:first-letter {
                text-transform: uppercase;
              }
            }
          }
        }

        p {
          // @include _(pv-xxs);
          // @include _(ph-none);
          @include _(m-none);
          @include _(fs-xl);
          @include _(fw-xbold);
          color: $white;
          text-align: center;
          text-shadow: 0px 1px 5px $grey-90;

          &.raised_amount {
            @include _(pv-xxs);
            @include _(ph-none);
            @include _(fs-xxl);
          }

          span {
            @include _(fw-xbold);
            display: block;
            line-height: 5.5rem;

            span {
              @include _(fw-xbold);
              display: inline-block;
            }
          }
        }

        .stars {
          position: absolute;
          bottom: -25px;
          left: -3px;
          height: 50px;
          width: 425px;
          display: flex;
          justify-content: center;

          .star {
            @include _(ph-xs);
            background: url("../../../img/icons/star.png") no-repeat;
            background-size: contain;
            background-position: 50% 50%;
            &:nth-child(1) {
              @include _(mt-xxs);
              height: 45px;
              width: 45px;
              transform: rotateZ(-10deg);
            }
            &:nth-child(2) {
              height: 60px;
              width: 60px;
            }
            &:nth-child(3) {
              @include _(mt-xxs);
              height: 45px;
              width: 45px;
              transform: rotateZ(10deg);
            }
          }
        }

        .beams {
          width: 640px;
          height: 415px;
          //   left: -3px;
          top: 70%;
          left: 0;
          right: 0;
          margin: 0 auto;
          position: absolute;
          .beam {
            height: 5px;
            background-color: $swatch-7;
            border-radius: $radius;
            position: absolute;

            &:nth-child(1) {
              top: 50%;
              left: -135px;
            }

            &:nth-child(2) {
              top: 40%;
              left: -100px;
              transform: rotateZ(10deg);
            }

            &:nth-child(3) {
              top: 58%;
              left: -100px;
              transform: rotateZ(-10deg);
            }

            &:nth-child(4) {
              top: 30%;
              left: -120px;
              transform: rotateZ(20deg);
            }

            &:nth-child(5) {
              top: 68%;
              left: -120px;
              transform: rotateZ(-20deg);
            }

            &:nth-child(6) {
              top: 20%;
              left: -85px;
              transform: rotateZ(30deg);
            }

            &:nth-child(7) {
              top: 80%;
              left: -85px;
              transform: rotateZ(-30deg);
            }

            &:nth-child(8) {
              top: 50%;
              right: -135px;
            }

            &:nth-child(9) {
              top: 40%;
              right: -100px;
              transform: rotateZ(350deg);
            }

            &:nth-child(10) {
              top: 58%;
              right: -100px;
              transform: rotateZ(10deg);
            }

            &:nth-child(11) {
              top: 30%;
              right: -120px;
              transform: rotateZ(340deg);
            }

            &:nth-child(12) {
              top: 68%;
              right: -120px;
              transform: rotateZ(20deg);
            }

            &:nth-child(13) {
              top: 20%;
              right: -85px;
              transform: rotateZ(330deg);
            }

            &:nth-child(14) {
              top: 80%;
              right: -85px;
              transform: rotateZ(30deg);
            }

            &.long {
              width: 80px;
            }
            &.short {
              width: 35px;
            }
          }
        }
      }
    }
  }
}
