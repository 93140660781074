.gtg {
  &-wrapper {
    overflow: hidden;
    .main {
      width: 1080px;
      height: 1920px;
      background: grey;

      .video-wrapper {
        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
        }

        &.up {
          //   transform: translateY(-400px);
          transform: translateY(0px);
          //   transition: transform 1.56s ease-in-out;
          animation: videoUp 45s 1 ease-in-out;
        }
        @keyframes videoUp {
          0% {
            transform: translateY(0px);
          }
          3% {
            transform: translateY(-454px);
          }
          97% {
            transform: translateY(-454px);
          }
          100% {
            transform: translateY(0px);
          }
        }
      }

      .viewport {
        position: relative;
        // height: 100vh;
        text-align: center;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.6);

        .legal {
          @include _(fs-xxs);
          width: 75%;
          margin: 0 auto;
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          color: $white;
        }

        .content-wrapper2 {
          //   transform: translateY(300%);
          //   background-color: $swatch-1;

          .ribbon {
            position: absolute;
            top: 24%;
            left: 0;
            margin: 0 auto;
            right: 0;

            &.hide {
              opacity: 0;
              transition: opacity 1.56s ease-in-out;
            }

            [class*="ribbon-"] {
              margin: 85px auto 0;
              transform-origin: 50% 50%;
              transform: rotate(-8.5deg);
              position: relative;
              z-index: 4;
              //   width: 287px;
              //   height: 56px;
              width: 600px;
              height: 80px;

              .inner {
                background: $swatch-1;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                display: block;
                width: 0;
                height: 100%;
                padding: 8px 0;
                text-shadow: 3px 3px 1px color-variant($swatch-1, -5);
                position: relative;
                z-index: 2;
                transform: skewX(-9deg);
                transition: width 0.12s ease-in-out;
              }

              &:before {
                content: "";
                transform-origin: 0 0;
                transform: rotate(-20.25deg) skewX(-9deg) translateX(158px);
                display: block;
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 4px;
                background: color-variant($swatch-1, -7);
                border-top: 3px solid color-variant($white, -7);
                border-bottom: 3px solid color-variant($white, -7);
                z-index: -1;
                transition: all 0.12s ease-in-out 0.7s;
              }
            }

            .ribbon-1 {
              .inner {
                letter-spacing: 10px;
                font-weight: 700;

                span {
                  @include _(fs-ml);
                  padding-top: 0.3rem;
                }
              }
            }

            .ribbon-2 {
              z-index: 3;
              font-size: 45px;
              //   width: 451px;
              //   height: 137px;
              width: 800px;
              height: 180px;
              margin-top: 30px;

              .inner {
                padding: 19px 0;

                span {
                  @include _(fw-bold);
                  @include _(pt-s);
                  font-size: 5.3rem;
                  line-height: 4.1rem;
                }

                &:before {
                  transform-origin: 50% 50%;
                  transform: rotate(180deg);
                }
              }

              &:before {
                width: 0;
                left: 11px;
                transform: rotate(-10.25deg) skewX(-9deg) translateX(451px);
                height: 47px;
                transition-delay: 0.94s;
              }
            }

            .ribbon-3 {
              z-index: 2;
              font-size: 9px;
              width: 500px;
              height: 80px;
              margin-top: 30px;

              .inner {
                color: $white;
                padding: 15px 0;
                // letter-spacing: 10px;

                span {
                  @include _(fs-l);
                  @include _(fw-bold);
                  line-height: 2.5rem;
                }
              }

              &:before {
                width: 0;
                height: 47px;
                left: 11px;
                transform: rotate(-10.25deg) skewX(-9deg) translateX(387px);
                transition-delay: 1.18s;
              }

              &:after {
                content: "";
                transform-origin: 100% 100%;
                transform: rotate(-15.3deg) skewX(9deg) translateX(72px);
                display: block;
                width: 0;
                height: 45px;
                position: absolute;
                bottom: 0;
                right: 4px;
                background: color-variant($swatch-1, -7);
                z-index: -1;
                transition-delay: 1.42s;
              }
            }

            &.active {
              .inner {
                width: 100%;
              }

              .ribbon-1 {
                .inner {
                  transition-delay: 0.82s;
                  border-top: 3px solid $white;
                  border-bottom: 3px solid $white;
                  background-image: linear-gradient(
                    $swatch-1,
                    color-variant($swatch-1, -5)
                  );
                  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0px 1px 0 #ccc,
                    0 3px 0 #ccc, 0 1px 0 #ccc, 0 6px 0 transparent,
                    0 7px 0 transparent, 0 8px 0 transparent,
                    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
                }

                --s: 70px;
                --d: 0px;
                --c: 20px;

                &:before {
                  width: 158px;
                  transform: rotate(-20.25deg) skewX(-9deg) translateX(4px);
                  clip-path: polygon(
                    0 0,
                    var(--s) var(--d),
                    var(--s) 0,
                    calc(100% - var(--s)) 0,
                    calc(100% - var(--s)) var(--d),
                    100% var(--d),
                    calc(100% - var(--c)) calc(50% + var(--d) / 2),
                    100% 100%,
                    calc(100% - var(--s) - var(--d)) 100%,
                    calc(100% - var(--s) - var(--d)) calc(100% - var(--d)),
                    calc(var(--s) + var(--d)) calc(100% - var(--d)),
                    calc(var(--s) + var(--d)) 100%,
                    0 100%,
                    var(--c) calc(50% + var(--d) / 2)
                  );
                }
              }

              .ribbon-2 {
                .inner {
                  transition-delay: 1.06s;
                  border-top: 3px solid $white;
                  border-bottom: 3px solid $white;
                  background-image: linear-gradient(
                    $swatch-1,
                    color-variant($swatch-1, -5)
                  );
                  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc,
                    0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 transparent,
                    0 7px 0 transparent, 0 8px 0 transparent,
                    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);

                  &:before,
                  &:after {
                    opacity: 1;
                  }
                }

                &:before {
                  width: 710px;
                  // transform: rotate(-5.25deg) skewX(-9deg) translateX(0);
                  transform: rotate(-6.15deg) skewX(-9deg) translateX(4px);
                }
              }

              .ribbon-3 {
                .inner {
                  transition-delay: 1.3s;
                  border-top: 3px solid $white;
                  border-bottom: 3px solid $white;
                  background-image: linear-gradient(
                    $swatch-1,
                    color-variant($swatch-1, -5)
                  );
                  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc,
                    0 1px 0 #ccc, 0 1px 0 #ccc, 0 6px 0 transparent,
                    0 7px 0 transparent, 0 8px 0 transparent,
                    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
                }

                &:before {
                  width: 650px;
                  // transform: rotate(-6.25deg) skewX(-9deg) translateX(0);
                  transform: rotate(-6.6deg) skewX(-9deg) translateX(4px);
                }

                &:after {
                  width: 75px;
                  transform: rotate(-25.3deg) skewX(9deg) translateX(-2px);
                  border-top: 3px solid color-variant($white, -7);
                  border-bottom: 3px solid color-variant($white, -7);
                  --s: 70px;
                  --d: 0px;
                  --c: 20px;
                  clip-path: polygon(
                    0 0,
                    var(--s) var(--d),
                    var(--s) 0,
                    calc(100% - var(--s)) 0,
                    calc(100% - var(--s)) var(--d),
                    100% var(--d),
                    calc(100% - var(--c)) calc(50% + var(--d) / 2),
                    100% 100%,
                    calc(100% - var(--s) - var(--d)) 100%,
                    calc(100% - var(--s) - var(--d)) calc(100% - var(--d)),
                    calc(var(--s) + var(--d)) calc(100% - var(--d)),
                    calc(var(--s) + var(--d)) 100%,
                    0 100%,
                    var(--c) calc(50% + var(--d) / 2)
                  );
                }
              }
            }

            .sj-logo {
              img {
                @include _(mt-ms);
                width: 16%;
              }
            }

            .search_button {
              position: relative;
              width: 57%;
              height: 213px;
              margin: 3rem auto 0;
              text-align: center;
              z-index: 9999;

              .rah-hint-wrapper {
                font-weight: 700 !important;
                // left: 104px !important; mac
                left: 80px !important;
                font-family: "SJ Sans", "Open Sans", "Helvetica Neue",
                  "Helvetica", "Arial", "sans-serif" !important;
                // top: 63px !important;
              }

              &:before {
                content: "";
                position: absolute;
                // right: 118px; mac
                right: 92px;
                top: 110px;
                height: 50px;
                width: 50px;
                display: inline-block;
                margin-right: 10px;
                mask: url("../../../img/search_glass.svg") no-repeat 50% 50%;
                mask-size: cover;
                background-color: $primary;
                z-index: 9999;
              }

              input {
                &.search_field {
                  @include _(fs-ml);
                  @include _(fw-bold);
                  @include _(mt-ml);
                  border-radius: $radius;
                  border: 0px;
                  padding: 2rem 5rem 2rem 2rem;
                  width: 450px;
                  line-height: 2.25rem;

                  &:focus {
                    outline: none;
                  }

                  &::placeholder {
                    color: $grey-10;
                  }
                }
              }

              .validation {
                @include _(p-xxs);
                @include _(fw-bold);
                width: 73%;
                margin: 0 auto;
                background: rgba($grey-90, 0.8);
                color: $white;
                border-bottom-left-radius: $radius;
                border-bottom-right-radius: $radius;
              }

              button {
                display: none;
                color: $white;
                padding: 1rem;
                border: 0;
                border-radius: $radius;
                margin: 25px auto 0;
                background: $primary;
              }

              .select_buttons {
                display: block;

                div {
                  display: inline-block;
                  @include _(mv-s);
                  @include _(mh-xxs);
                  @include _(fs-m);
                  color: $white;
                }

                label {
                  @include _(p-xs);
                  @include _(fw-bold);
                  background-color: $white;
                  color: $grey-90;
                  border-radius: $radius;

                  &.active {
                    background-color: $link-color;
                    color: $white;
                  }
                }

                input {
                  display: none;
                }

                span {
                  @include _(fs-m);
                }
              }
            }

            .fadeLeft {
              opacity: 0;
              transform: translateX(-100%);
              transition: all 0.3s ease-in-out 1.56s;
              display: block;
            }

            .fadeRight {
              opacity: 0;
              transform: translateX(100%);
              transition: all 0.3s ease-in-out 1.56s;
              display: block;
            }

            .fadeIn {
              opacity: 0;
              transition: all 0.8s ease-in-out 1.56s;
              display: block;
            }

            &.active {
              .fadeLeft,
              .fadeRight {
                opacity: 1;
                transform: translateX(0);
              }

              .fadeIn {
                opacity: 1;
              }
            }
          }
        }

        .content-wrapper {
          width: 110%;
          transform: translateY(200px) rotate(-2deg) translateX(-10px);

          .bar {
            height: 30px;
            width: 100%;
            background: rgba(255, 255, 255, 0.4);
          }

          .main-content {
            @include _(mv-xs);
            height: 600px;
            width: 100%;
            background: rgba(255, 255, 255, 0.4);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
              @include _(fs-l);
              @include _(fw-xbold);
              color: $black;
              transform: rotate(2deg);
            }

            img {
              @include _(mt-m);
              transform: rotate(2deg);
              width: 20%;
            }
          }
        }
      }
    }
  }
}
