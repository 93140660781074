// body {
//     background: $grey-90;
// }

.total-raised {
  .cta-primary {
    @include _(p-s);
    @include _(fw-bold);
    @include _(fs-m);
    background-color: $primary;
    border: 1px solid $swatch-2;
    border-bottom: 2px solid $swatch-2;
    border-radius: $radius;
  }
}
