.fundraiser-view {
  width: 100%;
  height: 961px;
  //   background-image: linear-gradient(
  //     $swatch-1 20%,
  //     color-variant($swatch-1, -5)
  //   );
  background-image: linear-gradient(135deg, $swatch-1 45%, $grey-90);
  background-size: 100%;
  transform: translateY(205%);
  // transform: translateY(100%);
  animation: fundraiser 45s 1 ease-in-out;
  clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);

  canvas {
    top: -15px !important;
  }

  &:before {
    background-color: transparent;
    border-top: 3px solid $white;
    bottom: -16px;
    content: " ";
    display: block;
    height: 68px;
    position: relative;
    // -webkit-transform: skewY(-2deg);
    // transform: skewY(-2deg);
    -webkit-transform: skewY(-1.8deg);
    transform: skewY(-1.8deg);
  }
}

@keyframes fundraiser {
  0% {
    transform: translateY(205%);
  }
  3% {
    transform: translateY(100%);
  }
  97% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(205%);
  }
}

.sj_logo {
  width: 40%;
  opacity: 1;
  position: absolute;
  top: 575px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  transform: translateY(350%);
  animation: logo 45s 1 ease-in-out;
}

@keyframes logo {
  0% {
    opacity: 0;
    transform: translateY(350%);
  }
  3% {
    opacity: 1;
    transform: translateY(0);
  }
  97% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(350%);
  }
}

.gtg-fundraiser {
  .username {
    @include _(mt-s);
    animation: username 1.5s 1 forwards;
    transform: rotate(-2deg);

    @keyframes username {
      0% {
        transform: translate(100%, 0);
      }
      80% {
        transform: translate(-1%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    span {
      @include _(fw-xbold);
      word-wrap: break-word;
      font-size: 80px;
      color: $white;
      line-height: 1em;
      // text-shadow: makelongshadow(20,1);
      // text-shadow: #533d4a 1px 1px, #533d4a 2px 2px, #533d4a 3px 3px,
      //   #533d4a 4px 4px, #533d4a 5px 5px, #533d4a 6px 6px;
      text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
        0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent,
        0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
    }
  }

  .amount_raised {
    @include _(mt-ms);
    animation: amount 1.5s 1 forwards;
    transform: rotate(-2deg);

    @keyframes amount {
      0% {
        transform: translate(-100%, 0);
      }
      80% {
        transform: translate(1%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    span {
      @include _(fw-xbold);
      font-size: 145px;
      color: $white;
      line-height: 1em;
      // text-shadow: makelongshadow(20,1);
      text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
        0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent,
        0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
    }

    p {
      @include _(fw-xbold);
      @include _(pt-m);
      @include _(m-none);
      @include _(fs-m);
      color: $white;
      text-shadow: 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
        0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
    }
  }

  .gtg-value {
    @include _(mt-ml);
    // @include _(pv-s);
    width: 100%;
    border-top: 2px solid color-variant($swatch-1, -7);
    border-bottom: 2px solid color-variant($swatch-1, -7);
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    animation: gtg 4s 1 forwards;
    opacity: 1;
    overflow: hidden;

    @keyframes gtg {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .icon_wrapper {
      //   width: 15%;
      width: 162px;
      // height: 172px;
      height: 250px;
      margin-right: 2rem;
      background-color: $white;
      position: relative;

      &:before {
        // left: 75%;
        left: 60%;
        border: solid transparent;
        content: " ";
        height: 100%;
        /* width: 100px; */
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-left-color: $swatch-1;
        // border-width: 86px;
        border-width: 115px;
        opacity: 0.2;
        background-color: red;
        clip-path: polygon(46% 0%, 95% 50%, 46% 100%, 30% 100%, 78% 50%, 30% 0);
        // animation: fundraiser 1s ease-in-out infinite;
      }

      &:after {
        left: 100%;
        border: solid transparent;
        content: " ";
        height: 100%;
        /* width: 100px; */
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-left-color: $white;
        // border-width: 86px;
        border-width: 125px;
      }

      img {
        @include _(mt-xs);
        // margin-left: 1.8rem;
        // width: 80%;
        //   margin-right: 2rem;
        //   width: 15%;
        width: 96%;
        position: absolute;
        left: 33px;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        z-index: 9999;
      }
    }
    span {
      @include _(fs-ml);
      @include _(fw-xbold);
      //   margin-left: 2rem;
      margin-left: 9rem;
      max-width: 700px;
      color: $white;
      text-align: left;
    }
  }
}
